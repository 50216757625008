// DateInput.js
import React from 'react';
import { FormGroup, Label, Input, Col } from "reactstrap";

function DateInput({ label, value, onChange }) {
  return (
    <FormGroup >
      <Label className='font-2' for="date">{label}</Label>
      <Col sm={6}><Input
        type="date"
        name="date"
        id="date"
        value={value}
        onChange={onChange}
      />
      </Col>
      
    </FormGroup>
  );
}

export default DateInput;
