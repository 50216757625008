import React from 'react'
import {FormGroup, Label, Input, Col} from 'reactstrap';

function TextInput({ label, value, onChange, placeholder }) {
  return (
    <FormGroup>
      <Label sm={2} className='font-2'>{label}</Label>
      <Col sm={8}>  <Input
        type="text"
        value={value}
        onChange={onChange}
        placeholder={placeholder}
      /></Col>
    
    </FormGroup>
  );
}

export default TextInput