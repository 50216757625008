import React, { useState, useEffect } from "react";
import {
  Button,
  Form,
  Alert,
  Spinner,
  Progress,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Row,
  Col,
} from "reactstrap";
import { db, storage, firebase } from "../../../firebase";
import "react-quill/dist/quill.snow.css";
import DropdownComponent from "../../forms/DropdownComponent";
import MediaUpload from "../../forms/MediaUpload";
import TextInput from "../../forms/TextInput";
import DateInput from "../../forms/DateInput";
import RichEditor from "../../forms/RichEditor";
import {
  addToUnsubmittedMediaUrls,
  removeFromUnsubmittedMediaUrls,
  deleteFromStorage,
  generateFileName,
} from "../../utils/mediaUtils";

function AddArt({ refreshList, addNotification }) {
  const [name, setName] = useState("");
  const [client, setClient] = useState("");
  const [description, setDescription] = useState("");
  const [date, setDate] = useState("");
  const [imageFiles, setImageFiles] = useState([]);
  const [videoFiles, setVideoFiles] = useState([]);
  const [logoFiles, setLogoFiles] = useState([]);
  const [imagePreviews, setImagePreviews] = useState([]);
  const [videoPreviews, setVideoPreviews] = useState([]);
  const [logoPreviews, setLogoPreviews] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [uploadingMedia, setUploadingMedia] = useState(null);
  const [uploadingIndex, setUploadingIndex] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [brief, setBrief] = useState("");
  const [selectedKeywords, setSelectedKeywords] = useState([]);
  const [dropdownValues, setDropdownValues] = useState({
    type: [],
    media: [],
    tools: [],
    keywords: [], // Add this line
  });
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [selectedTools, setSelectedTools] = useState([]);
  const [selectedMedia, setSelectedMedia] = useState([]);
  const [activeTab, setActiveTab] = useState("1");

  const firestoreCollection = "arts";

  useEffect(() => {
    let isMounted = true;

    const fetchDropdownValues = async () => {
      try {
        const doc = await db
          .collection("dropdowns")
          .doc(firestoreCollection)
          .get();
        if (doc.exists && isMounted) {
          setDropdownValues(doc.data());
        }
      } catch (error) {
        console.error("Error fetching dropdown values:", error);
        addNotification(
          "Error fetching dropdown values. Please refresh the page.",
          "danger"
        );
      }
    };

    fetchDropdownValues();

    return () => {
      isMounted = false;
    };
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const imageUrls = imageFiles.map((file) => file.url);
      const videoUrls = videoFiles.map((file) => file.url);
      const logoUrls = logoFiles.map((file) => file.url);

      const newItem = {
        name,
        type: selectedTypes,
        media: selectedMedia,
        client: client.split(",").map((item) => item.trim()),
        description,
        date,
        tools: selectedTools,
        ImageUrl: imageUrls,
        VideoUrl: videoUrls,
        LogoUrl: logoUrls,
        isOn: false,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        modifiedAt: firebase.firestore.FieldValue.serverTimestamp(),
        keywords: selectedKeywords,
        brief,
      };

      const newItemRef = await db.collection(firestoreCollection).add(newItem);

      await removeFromUnsubmittedMediaUrls([
        ...imageUrls,
        ...videoUrls,
        ...logoUrls,
      ]);

      if (refreshList) {
        refreshList();
      }
      addNotification("New Item added successfully", "success");

      setName("");
      setClient("");
      setDescription("");
      setDate("");
      setImageFiles([]);
      setVideoFiles([]);
      setLogoFiles([]);
      setImagePreviews([]);
      setVideoPreviews([]);
      setLogoPreviews([]);

      return newItemRef.id;
    } catch (error) {
      console.error("Error during form submission:", error);
      addNotification(
        "Error during form submission. Please try again.",
        "danger"
      );
    } finally {
      setIsLoading(false);
    }
  };

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <Form className="footer" onSubmit={handleSubmit}>
      <Nav tabs justified >
        <NavItem>
          <NavLink
            className={{ active: activeTab === "1" }}
            onClick={() => {
              toggle("1");
            }}
          >
            General
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={{ active: activeTab === "2" }}
            onClick={() => {
              toggle("2");
            }}
          >
            Properties
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={{ active: activeTab === "3" }}
            onClick={() => {
              toggle("3");
            }}
          >
            Media
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={{ active: activeTab === "4" }}
            onClick={() => {
              toggle("4");
            }}
          >
            Description
          </NavLink>
        </NavItem>
      </Nav>
      <hr />
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <Row className="mt-2">
          <Col > 
            <TextInput
            label="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Name"
          />
          <TextInput
            label="Client"
            value={client}
            onChange={(e) => setClient(e.target.value)}
            placeholder="Clients (comma separated, e.g. Developer, Designer)"
          />
          <DateInput
            label="Date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
          />
           </Col>
          </Row>
        
        </TabPane>
        <TabPane tabId="2">
        <DropdownComponent
            label="Type"
            items={dropdownValues.type}
            selectedItems={selectedTypes}
            setSelectedItems={setSelectedTypes}
          />
          <DropdownComponent
            label="Media"
            items={dropdownValues.media}
            selectedItems={selectedMedia}
            setSelectedItems={setSelectedMedia}
          />
          <DropdownComponent
            label="Tools"
            items={dropdownValues.tools}
            selectedItems={selectedTools}
            setSelectedItems={setSelectedTools}
          />
          <DropdownComponent
            label="Keywords"
            items={dropdownValues.keywords}
            selectedItems={selectedKeywords}
            setSelectedItems={setSelectedKeywords}
          />
        </TabPane>
        <TabPane tabId="3">
          <MediaUpload
            label="Images"
            type="images"
            files={imageFiles}
            setFiles={setImageFiles}
            previews={imagePreviews}
            setPreviews={setImagePreviews}
            addNotification={addNotification}
            setUploadingMedia={setUploadingMedia}
            setUploadingIndex={setUploadingIndex}
            setUploadProgress={setUploadProgress}
            deleteFromStorage={deleteFromStorage}
            removeFromUnsubmittedMediaUrls={removeFromUnsubmittedMediaUrls}
            storage={storage}
            generateFileName={generateFileName}
            addToUnsubmittedMediaUrls={addToUnsubmittedMediaUrls}
            setImageFiles={setImageFiles}
            setVideoFiles={setVideoFiles}
            setLogoFiles={setLogoFiles}
            firestoreCollection={firestoreCollection}
          />
          <hr />
          <MediaUpload
            label="Videos"
            type="videos"
            files={videoFiles}
            setFiles={setVideoFiles}
            previews={videoPreviews}
            setPreviews={setVideoPreviews}
            addNotification={addNotification}
            setUploadingMedia={setUploadingMedia}
            setUploadingIndex={setUploadingIndex}
            setUploadProgress={setUploadProgress}
            deleteFromStorage={deleteFromStorage}
            removeFromUnsubmittedMediaUrls={removeFromUnsubmittedMediaUrls}
            storage={storage}
            generateFileName={generateFileName}
            addToUnsubmittedMediaUrls={addToUnsubmittedMediaUrls}
            setImageFiles={setImageFiles}
            setVideoFiles={setVideoFiles}
            setLogoFiles={setLogoFiles}
            firestoreCollection={firestoreCollection}
          />
          <hr />
          <MediaUpload
            label="Logo"
            type="logos"
            files={logoFiles}
            setFiles={setLogoFiles}
            previews={logoPreviews}
            setPreviews={setLogoPreviews}
            addNotification={addNotification}
            setUploadingMedia={setUploadingMedia}
            setUploadingIndex={setUploadingIndex}
            setUploadProgress={setUploadProgress}
            deleteFromStorage={deleteFromStorage}
            removeFromUnsubmittedMediaUrls={removeFromUnsubmittedMediaUrls}
            storage={storage}
            generateFileName={generateFileName}
            addToUnsubmittedMediaUrls={addToUnsubmittedMediaUrls}
            setImageFiles={setImageFiles}
            setVideoFiles={setVideoFiles}
            setLogoFiles={setLogoFiles}
            firestoreCollection={firestoreCollection}
          />
        </TabPane>
        <TabPane tabId="4">
          <TextInput
            label="Brief"
            value={brief}
            onChange={(e) => setBrief(e.target.value)}
            placeholder="Brief description"
          />
          <RichEditor
            label="Description"
            value={description}
            onChange={setDescription}
          />
        </TabPane>
      </TabContent>

      <hr />
      <Button
        className="mt-1 primary"
        // color="primary"
        type="submit"
        disabled={isLoading || uploadingMedia}
      >
        {isLoading ? <Spinner size="sm" color="light" /> : "Add"}
      </Button>

      {uploadingMedia && (
        <Alert className="mt-2 primary">
          <Spinner /> Uploading {uploadingMedia}{" "}
          {uploadingIndex !== null && `${uploadingIndex}`}, please wait...
          <Progress className="mt-2" value={uploadProgress} max={100} />
        </Alert>
      )}
    </Form>
  );
}

export default AddArt;
