import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import 'firebase/compat/auth';

const firebaseConfig = {
  apiKey: "AIzaSyDuM8Hyk4aqL89611UbW6SPpDv0D-ZxOMk",
  authDomain: "admin-kadinlar-kodluyor.firebaseapp.com",
  projectId: "admin-kadinlar-kodluyor",
  storageBucket: "admin-kadinlar-kodluyor.appspot.com",
  messagingSenderId: "1036382733558",
  appId: "1:1036382733558:web:fa3e09d1cd98303fd8d8d7"
};

firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();
const storage = firebase.storage();
const auth = firebase.auth();

export { firebase, db, storage, auth };
