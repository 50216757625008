import React, {useState} from "react";
import {Nav,NavItem,NavLink,TabContent,TabPane,Row,Col,Card,CardTitle,CardText,Button,} from "reactstrap";
import { Link, useLocation } from "react-router-dom";
import { faUserGraduate, faBriefcase, faBellConcierge, faWrench, faAward, faPalette, faCode, faFolderClosed, faGamepad, faPencil} from "@fortawesome/free-solid-svg-icons";
  import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Tabs() {

    const location = useLocation();
    const [activeTab, setActiveTab] = useState("1"); // Default to the first tab
  
    const toggleTab = (tab) => {
      if (activeTab !== tab) setActiveTab(tab);
    };

    const getNavLinkClass = (path) => {
        return isActive(path) ? "current" : "";
      };

    const isActive = (path) => location.pathname.includes(path);

  return (
    <div className="navigation header tabs">
      <Nav tabs justified>
        <NavItem>
          <NavLink 
          className={activeTab === "1" ? "active" : ""}
            onClick={() => toggleTab("1")}>
            Personal
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink  
          className={activeTab === "2" ? "active" : ""}
            onClick={() => toggleTab("2")}>
            Works
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink  className={activeTab === "3" ? "active" : ""}
            onClick={() => toggleTab("3")}>
            Blogs
          </NavLink>
        </NavItem>
      </Nav>

      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <Nav tabs pills justified>
            <NavItem className="navigation-item" active={isActive("/dashboard/education")}>
              <NavLink className={ isActive("/dashboard/education") ? "active" : ""} tag={Link} to="/dashboard/education">
              <FontAwesomeIcon icon={faUserGraduate} /> <p>Education</p> 
              </NavLink>
            </NavItem>
            <NavItem className="navigation-item" active={isActive("/dashboard/workexperience")}>
              <NavLink className={isActive("/dashboard/workexperience") ? "active" : ""} tag={Link} to="/dashboard/workexperience">
              <FontAwesomeIcon icon={faBriefcase} /> <p>Experience</p>
              </NavLink>
            </NavItem>
            <NavItem className="navigation-item" active={isActive("/dashboard/expertises")}>
              <NavLink className={isActive("/dashboard/expertises") ? "active" : ""} tag={Link} to="/dashboard/expertises">
              <FontAwesomeIcon icon={faBellConcierge} /><p>Expertises</p>
              </NavLink>
            </NavItem>
            <NavItem className="navigation-item" active={isActive("/dashboard/skills")}>
              <NavLink className={isActive("/dashboard/skills") ? "active" : ""} tag={Link} to="/dashboard/skills">
              <FontAwesomeIcon icon={faWrench} /><p>Skills</p>
              </NavLink>
            </NavItem>
            <NavItem className="navigation-item" active={isActive("/dashboard/achievements")}>
              <NavLink className={isActive("/dashboard/achievements") ? "active" : ""} tag={Link} to="/dashboard/achievements">
              <FontAwesomeIcon icon={faAward} /><p>Achievements</p>
              </NavLink>
            </NavItem>
          </Nav>
        </TabPane>
        <TabPane tabId="2">
          <Nav justified pills>
            <NavItem className="navigation-item" active={isActive("/dashboard/digitalarts")}>
              <NavLink  className={isActive("/dashboard/digitalarts") ? "active" : ""} tag={Link} to="/dashboard/digitalarts">
              <FontAwesomeIcon icon={faPalette} /> <p>Arts</p>
              </NavLink>
            </NavItem>
            <NavItem className="navigation-item" active={isActive("/dashboard/webapps")}>
              <NavLink className={isActive("/dashboard/webapps") ? "active" : ""} tag={Link} to="/dashboard/webapps">
              <FontAwesomeIcon icon={faCode} /><p>Apps</p>
              </NavLink>
            </NavItem>
            <NavItem className="navigation-item" active={isActive("/dashboard/projects")}>
              <NavLink className={isActive("/dashboard/projects") ? "active" : ""} tag={Link} to="/dashboard/projects">
              <FontAwesomeIcon icon={faFolderClosed} /> <p>Projects</p>
              </NavLink>
            </NavItem>
            <NavItem className="navigation-item" active={isActive("/dashboard/games")}>
              <NavLink className={isActive("/dashboard/games") ? "active" : ""} tag={Link} to="/dashboard/games">
              <FontAwesomeIcon icon={faGamepad} /> <p>Games</p>
              </NavLink>
            </NavItem>
          </Nav>
        </TabPane>
        <TabPane tabId="3">
          <Nav justified pills>
            <NavItem className="navigation-item" active={isActive("/dashboard/blogs")}>
              <NavLink className={isActive("/dashboard/blogs") ? "active" : ""} tag={Link} to="/dashboard/blogs">
              <FontAwesomeIcon icon={faPencil} /> <p> Blogs</p>
              </NavLink>
            </NavItem>{" "}
            <NavItem className="navigation-item" active={isActive("/dashboard/news")}>
              <NavLink className={isActive("/dashboard/news") ? "active" : ""} tag={Link} to="/dashboard/news">
              <FontAwesomeIcon icon={faPencil} /> <p> News</p>
              </NavLink>
            </NavItem>{" "}
            <NavItem className="navigation-item" active={isActive("/dashboard/announcements")}>
              <NavLink className={isActive("/dashboard/announcements") ? "active" : ""} tag={Link} to="/dashboard/announcements">
              <FontAwesomeIcon icon={faPencil} /> <p> Announcements</p>
              </NavLink>
            </NavItem>{" "}
          </Nav>
        </TabPane>
      </TabContent>
    </div>
  );
}

export default Tabs;
