import React, {useState} from "react";
import {Routes, Route } from 'react-router-dom';
import DigitalArts from "./managers/arts/Arts";
import WebApps from './managers/apps/Apps';
import NotFound from './pages/NotFound';
import ProtectedRoute from "./ProtectedRoute";
import Achievements from './managers/Achievements';
import Blogs from './managers/Blogs';
import WorkExperience from './managers/WorkExperience';
import Education from './managers/Education';
import Skills from './managers/Skills';
import Games from './managers/games/Games';
import Projects from './managers/Projects';
import Expertises from './managers/Expertises';
import Notification from './Notification.js';
import Clean from './CleanUp';
import News from './managers/news/News.js'
import Announcements from './managers/announcements/Announcements.js';


function Dashboard() {

  const [notifications, setNotifications] = useState([]);

  const addNotification = (message, type) => {
    const id = Date.now();
    setNotifications([...notifications, { id, message, type }]);
  };

  const removeNotification = (id) => {
    setNotifications(notifications.filter(notif => notif.id !== id));
  };

  return (
    <div className="dashboard ">
      {/* <ResponsiveNavigation /> */}
      
      <div className="main-content">
     
      <Routes>
      <Route path="digitalarts" element= {<ProtectedRoute><DigitalArts addNotification={addNotification} /></ProtectedRoute>}/>
      <Route path="webapps" element= {<ProtectedRoute><WebApps addNotification={addNotification} /></ProtectedRoute>}/>
      <Route path="achievements" element= {<ProtectedRoute><Achievements addNotification={addNotification} /></ProtectedRoute>}/>
      <Route path="blogs" element= {<ProtectedRoute><Blogs addNotification={addNotification} /></ProtectedRoute>}/>
      <Route path="workexperience" element= {<ProtectedRoute><WorkExperience addNotification={addNotification} /></ProtectedRoute>}/>
      <Route path="skills" element= {<ProtectedRoute><Skills addNotification={addNotification} /></ProtectedRoute>}/>
      <Route path="projects" element= {<ProtectedRoute><Projects addNotification={addNotification} /></ProtectedRoute>}/>
      <Route path="games" element= {<ProtectedRoute><Games addNotification={addNotification} /></ProtectedRoute>}/>
      <Route path="education" element= {<ProtectedRoute><Education addNotification={addNotification} /></ProtectedRoute>}/>
      <Route path="expertises" element= {<ProtectedRoute><Expertises addNotification={addNotification} /></ProtectedRoute>}/>
      <Route path="news" element= {<ProtectedRoute><News addNotification={addNotification} /></ProtectedRoute>}/>
      <Route path="announcements" element= {<ProtectedRoute><Announcements addNotification={addNotification} /></ProtectedRoute>}/>
      <Route path="clean" element= {<ProtectedRoute><Clean addNotification={addNotification} /></ProtectedRoute>}/>
      </Routes>
      </div>
      <div className="notification-stack">
  {notifications.map(notif => (
    <Notification 
      key={notif.id} 
      message={notif.message} 
      type={notif.type}
      onClose={() => removeNotification(notif.id)}
    />
  ))}
</div>
    </div>
  );
}

export default Dashboard;
